.section-full {
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
}
.section-mid {
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
}

.image-perro-gato {
  background-image: url("./images/perro_gato_verde.jpg");
  background-color: transparent;
  backdrop-filter: blur(5px);
}
